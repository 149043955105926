/*
 * @Author: qxy
 * @Date: 2020-03-27 14:15:36
 * @Last Modified by: bowang
 * @Last Modified time: 2023-04-21 11:49:33
 */
import webviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
const directive = {
    bind(el, { value }) {
        if (!value) {
            return;
        }
        el.addEventListener('click', () => {
            webviewInterface.callHandler('logEasycashUmengEvent', value);
        });
    }
};
export default directive;
