/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:32
 */

import Vue from 'vue';
import VueRouter from 'vue-router';

import {createVueRouter} from 'ssr-common/vue/router';

import routes from './routes';

Vue.use(VueRouter);

export const createRouter = () => createVueRouter({routes});
