/*
 * @Author: xiaodongyu
 * @Date 2019-04-09 11:29:26
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2019-04-12 18:11:45
 */

export default {
    GENERAL: 'GENERAL',
    REGISTER: 'REGISTER',
    LOAN_MATCH: 'LOAN_MATCH',
    FUND: 'FUND'
};
