/*
 * @Author: zhaoyang
 * @Date: 2024-07-18 12:28:44
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-22 16:46:40
 */
import sensors from 'sa-sdk-javascript';
import pageleave from 'sa-sdk-javascript/dist/web/plugin/pageleave/index.es6';
import pageload from 'sa-sdk-javascript/dist/web/plugin/pageload/index.es6';
import { STAGE } from '@shared/common/constant/stage';
const isProd = /prod/i.test(STAGE);
// 数据接收地址按照国家区分，每个国家有一个生产和一个非生产数据接收地址
const countryServerUrlMap = {
    indo: {
        default: 'https://datasink.easycash.id/sa?project=default',
        prod: 'https://datasink.easycash.id/sa?project=production'
    }
};
export const initSensors = ({ country, platformName, businessName, userId = '', useAppJsBridge = false }, sensorsConfig = {}) => {
    sensors.init({
        server_url: countryServerUrlMap[country][isProd ? 'prod' : 'default'],
        is_track_single_page: true,
        use_client_time: true,
        send_type: 'image',
        show_log: !isProd,
        app_js_bridge: useAppJsBridge,
        heatmap: {
            // 是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap: 'default',
            // 是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map: 'not_collect'
        },
        ...sensorsConfig
    });
    // 全局公参
    sensors.registerPage({
        fin_user_id: userId,
        is_login: !!userId,
        platform_name: platformName,
        business_name: businessName,
    });
    if (userId)
        sensors.login(userId);
    sensors.quick('autoTrack'); // 用于采集 $pageview 事件
    sensors.use(pageleave, {}); // 用于采集 WebPageLeave 事件
    sensors.use(pageload, { max_duration: 10 * 60 }); // 10分钟 用于采集 WebPageLoad 事件
    return sensors;
};
export default sensors;
