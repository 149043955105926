<!-- @Author: ruiwang -->
<!-- @Date: 2023-12-14 18:17:49 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-12-14 18:17:49 -->

<template>
    <div
        v-show="loading"
        class="ec-layout"
    >
        <nav-bar
            ref="navBarRef"
            class="nav-bar"
            :style="{...navBar, paddingTop: safeHeight ? `${safeHeight}px` : undefined}"
            :custom-back="customBack"
            v-bind="navBar"
        >
            <template
                v-if="$slots['navBar.right']"
                #right
            >
                <slot name="navBar.right" />
            </template>
            <template
                v-if="$slots['navBar.title']"
                #title
            >
                <slot name="navBar.title" />
            </template>
        </nav-bar>
        <div
            class="ec-layout-wrapper"
            :style="wrapperStyleVal"
        >
            <div
                ref="content"
                class="ec-layout-wrapper-content"
                :class="{'content-fixed': footerFixed}"
                :style="contentStyle"
                v-on="$listeners"
            >
                <slot />
            </div>
            <div
                v-if="$slots.footer"
                class="ec-layout-wrapper-footer"
                :class="{
                    'footer-fixed': footerFixed
                }"
                :style="footerStyle"
            >
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
import {configureScope} from '@sentry/vue';

import ua from 'ssr-common/util/webview/ua-parsed';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import NavBar from 'ssr-common/vue/component/nav-bar';

export default {
    name: 'EcLayout',

    components: {
        NavBar
    },

    props: {
        navBar: {
            type: Object,
            default: () => ({})
        },

        wrapperStyle: {
            type: Object,
            default: () => ({})
        },

        contentStyle: {
            type: Object,
            default: () => ({})
        },

        footerStyle: {
            type: Object,
            default: () => ({})
        },

        footerFixed: {
            type: Boolean,
            default: false
        },

        customBack: {
            type: [Function, undefined],
            default: undefined
        },

        calcHeight: {
            type: Boolean,
            default: false
        },

        emitHeightChange: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            loading: false,
            safeHeight: undefined,
            barHeight: 0,
            navBarHeight: null
        };
    },

    computed: {
        wrapperStyleVal() {
            const {wrapperStyle, navBarHeight, calcHeight} = this;

            if (calcHeight) {
                return {
                    'padding-top': `${navBarHeight}px`,
                    ...wrapperStyle
                };
            }

            return wrapperStyle;
        },

        isFixed() {
            const {navBar: {position}} = this;
            const isFixed = position === 'fixed';

            return isFixed;
        }
    },

    async mounted() {
        this.performAction();
        if (ua.isAndroid) {
            const height = await this.getSafeHeight();
            this.safeHeight = height;
        }

        if (ua.isIOS && (this.calcHeight || this.emitHeightChange)) {
            const safeAreaInsets = await import('safe-area-insets');
            safeAreaInsets.onChange(style => {
                if (style.top) {
                    this.navBarHeight = this.getBarHeight();
                    this.$emit('navHeightChange', this.navBarHeight);
                }
            });
        }

        this.timer = setTimeout(() => {
            this.loading = true;
            this.$nextTick(this.emitNavMounted);
        }, 100);
    },

    beforeDestroy() {
        clearTimeout(this.timer);
        clearTimeout(this.getNavHeightTimer);
    },

    methods: {
        emitNavMounted() {
            this.navBarHeight = this.$refs.navBarRef?.$el?.offsetHeight;
            this.barHeight = this.getBarHeight();

            if (this.$refs.navBarRef?.visible && !this.navBarHeight) {
                this.getNavHeightTimer = setTimeout(this.emitNavMounted, 50);

                return;
            }

            this.handleNavBarMounted();
        },

        getBarHeight() {
            return this.$refs.navBarRef?.$el?.offsetHeight;
        },

        performAction() {
            try {
                if (configureScope) {
                    performance.mark('EC_LAYOUT_TIME');

                    performance.measure('eclayoutMeasure', 'HTML_PERFDATE_START', 'EC_LAYOUT_TIME');

                    const measures = performance.getEntriesByName('eclayoutMeasure');
                    const measure = measures[0];

                    configureScope(scope => {
                        const transaction = scope.getTransaction();
                        if (transaction) {
                            transaction.setMeasurement('LayoutData', measure.duration, 'millisecond');
                        }
                    });
                }
            } catch (error) {
                // ignore
            }
        },

        async getSafeHeight() {
            if (!ua.isAndroid) return 0;

            let height = 0;
            try {
                height = await WebviewInterface.getStatusBarHeight();
            } catch (err) {
                // ignore
            }

            return height;
        },

        scrollTo(offset) {
            const el = this.$refs.content;
            if (el) {
                el.scrollTop = offset;
            }
        },

        handleNavBarMounted() {
            const barHeight = this.getBarHeight();
            this.$emit('navMounted', barHeight);
        }
    }
};
</script>

<style lang="scss" scoped>
div.ec-layout {
    padding: 0;
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &-wrapper {
        font-family: "Helvetica", sans-serif;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;

        &-content {
            box-sizing: border-box;
            padding: 0.25rem 0.15rem 0;
            flex: 1;
            overflow: auto;
        }

        .content-fixed {
            padding-bottom: 1.1rem;
        }

        &-footer {
            width: 100%;
            box-sizing: border-box;
            padding: 0.15rem;
        }

        .footer-fixed {
            position: fixed;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgb(245, 251, 251, 0.5), rgba(245, 251, 251) 0.25rem);
        }
    }
}
</style>

