/*
 * @Author: ruiwang
 * @Date: 2023-12-13 14:36:15
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-12-13 14:41:46
 */
// eslint-disable max-len

export default {
    name: 'backIcon',

    props: {
        color: {
            type: String,
            default: '#333'
        },

        newArrow: {
            type: Boolean,
            default: false
        }
    },

    render() {
        const {color, newArrow} = this;
        const defaultSvg = (
            <svg
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <g
                    id="icon_back"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                >
                    <path
                        d="M20.0076373,10.4939024 C20.286543,10.4939024 20.5250771,10.6006087 20.723247,10.8140244 C20.9214168,11.0274401 21.0131607,11.278962 20.9984814,11.5685976 C20.9838022,11.8277452 20.881049,12.0487796 20.6902188,12.2317073 C20.4993886,12.4146351 20.2792032,12.5060976 20.0296561,12.5060976 L7.41290754,12.5060976 L12.1689593,17.2621951 C12.3597895,17.4603668 12.458873,17.696645 12.4662126,17.9710366 C12.4735522,18.2454282 12.3818083,18.4817063 12.1909781,18.679878 C12.0001479,18.8932938 11.7652835,19 11.4863778,19 C11.2368307,19 11.0166453,18.9085375 10.8258151,18.7256098 L4.30826262,12.2088415 C4.10275318,12.0106697 4,11.7705807 4,11.4885671 C4,11.2065535 4.10275318,10.9664644 4.30826262,10.7682927 L10.7817776,4.27439024 C10.987287,4.0914625 11.2221514,4 11.4863778,4 C11.7506043,4 11.9744594,4.10289531 12.15795,4.30868902 C12.3414405,4.51448274 12.4331844,4.7545718 12.4331844,5.02896341 C12.4331844,5.30335503 12.3304313,5.53963316 12.1249218,5.73780488 L7.36887003,10.4939024 L20.0076373,10.4939024 Z"
                        fill={color}
                    />
                </g>
            </svg>
        );
        const newArrowSvg = (
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.00004 10.335L14 4.33496L15.415 5.74996L9.41504 11.75L15.415 17.75L14 19.175L8.00004 13.175L7.29254 12.4675C7.10476 12.2799 6.99925 12.0254 6.99925 11.76C6.99925 11.4946 7.10476 11.24 7.29254 11.0525L8.00004 10.335Z"
                    fill={color}
                />
            </svg>
        );

        return newArrow ? newArrowSvg : defaultSvg;
    }
};
