/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-03-29 下午03:49:28
 */

import Vue from 'vue';
import VueI18n from 'vue-i18n';

import EN_US from './languages/en-us.json';
import ID from './languages/id.json';
import ZH from './languages/zh.json';

Vue.use(VueI18n);

export const createI18n = context => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'id',
    messages: {
        en: EN_US,
        id: ID,
        zh: ZH
    },
    silentTranslationWarn: true
});

