/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-10-19 18:17:14
 */

import Vue from 'vue';
import Vuex, {Store} from 'vuex';

import baseData from './module/base-data';
import contact from './module/contact';
import userStatus from './module/user-status';

Vue.use(Vuex);

export const createStore = () => new Store({
    modules: {
        baseData,
        contact,
        userStatus
    }
});
