/*
 * @Author: qxy
 * @Date: 2020-03-27 14:12:13
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-28 21:56:49
 */
import reachShow from 'ssr-common/vue/directive/reach-show';
import { BRAND } from 'easycash/common/constant/config';
import clickRipple from './click-ripple';
import generateEvent from './event';
import eventV2 from './event-v2';
import scrollBar from './scroll-bar';
import umeng from './umeng';
const event = generateEvent(BRAND);
export default {
    install(Vue) {
        Vue.directive('reach-show', reachShow);
        Vue.directive('umeng', umeng);
        Vue.directive('yqg-event', event);
        Vue.directive('event', eventV2);
        Vue.directive('scroll-bar', scrollBar);
        Vue.directive('click-ripple', clickRipple);
    }
};
