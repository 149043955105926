/**
 * @Author: xiaodongyu
 * @Date: 2024-01-18 17:37:18
 * @Last Modified by: xiaodongyu
 * @Last Modified time: 2024-01-18 17:52:58
 */
export default {
    methods: {
        saveEventParam(key, value) {
            try {
                localStorage.setItem(key, JSON.stringify(value));
            }
            catch (e) {
                // ignore saveEventParam error
            }
        },
        getEventParam(key) {
            try {
                return JSON.parse(localStorage.getItem(key));
            }
            catch (e) {
                // ignore getEventParam error
            }
        },
        removeEventParam(key) {
            localStorage.removeItem(key);
        }
    }
};
