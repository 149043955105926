/*
 * @Author: qxy
 * @Date: 2019-10-15 13:42:54
 * @Last Modified by: qxy
 * @Last Modified time: 2019-10-15 17:17:18
 */

import QuestionType from 'easycash/app/webview/lender/help-center/constant/question-type';

const routes = [
    {
        path: '/webview/lender/about-us',
        name: 'aboutUs',
        component: () => import('easycash/app/webview/lender/about-us')
    },
    {
        path: '/webview/lender/help-center',
        name: 'helpCenterLender',
        component: () => import('easycash/app/webview/lender/help-center'),
        redirect: {name: 'questionLender', params: {type: QuestionType.GENERAL}},
        children: [
            {
                path: ':type',
                name: 'questionLender',
                component: () => import('easycash/app/webview/lender/help-center/question')
            }
        ]
    }
];

export default routes;
