<!-- @Author: ruiwang -->
<!-- @Date: 2023-12-13 14:10:43 -->
<!-- @Last Modified by: ruiwang -->
<!-- @Last Modified time: 2023-12-13 14:10:43 -->

<template>
    <div
        v-show="visible"
        class="nav-bar-container"
    >
        <div
            class="nav-bar"
            :style="navStyle"
        >
            <div
                v-if="!noReturn"
                v-yqg-event="event"
                class="nav-bar-left"
                @click="handleBack"
            >
                <slot name="left">
                    <back-icon
                        class="nav-bar-lef-icon"
                        :style="{left: backLeft}"
                        v-bind="{color, newArrow}"
                    />
                </slot>
            </div>
            <div class="nav-bar-tit">
                <slot name="title">
                    <div v-if="!noTitle">
                        {{ title }}
                    </div>
                </slot>
            </div>
            <div
                class="nav-bar-right"
            >
                <slot name="right" />
            </div>
        </div>
    </div>
</template>

<script>
import ua from 'ssr-common/util/webview/ua-parsed';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import backIcon from './icons/back.jsx';

const NavBarBackButton = {
    tag: 'NavBarBackButton',
    businessEvent: 'Easycash_NavBar_BackButton',
    elementType: 'Button',
    eventType: 'C'
};

export default {
    name: 'NavBar',

    components: {
        backIcon
    },

    props: {
        event: {
            type: Object,
            default: () => NavBarBackButton
        },

        customBack: {
            type: [Function, undefined],
            default: undefined
        },

        color: {
            type: String,
            default: '#333'
        },

        noTitle: {
            type: Boolean,
            default: false
        },

        newArrow: {
            type: Boolean,
            default: false
        },

        newBack: {
            type: Boolean,
            default: false
        },

        alwaysShow: {
            type: Boolean,
            default: false
        },

        backLeft: {
            type: String,
            default: ''
        },

        navStyle: {
            type: Object,
            default: () => ({})
        }
    },

    data() {
        return {
            newTitle: ''
        };
    },

    computed: {
        noReturn() {
            return this.$route.query.noReturn === '1';
        },

        visible() {
            const {$route: {query: {reqSpeBar, isAppBarHid}}, alwaysShow} = this;
            if (alwaysShow) return true;

            const visible = reqSpeBar === '1'
                && (isAppBarHid === '1' || Array.isArray(isAppBarHid));

            return visible;
        },

        title() {
            if (this.$isServer) return this.$ssrContext.title;

            return this.newTitle || document.title;
        },

        isAndroid() {
            return ua.isAndroid;
        },
    },

    mounted() {
        this.observeTitle();
    },

    methods: {
        goBack() {
            if (ua.isIOS) {
                if (window.history.length > 1 && this.newBack) {
                    window.history.back();
                } else {
                    WebviewInterface.callHandler('goBack');
                }
            } else if (ua.isAndroid) {
                WebviewInterface.callHandler('goBack');
            } else {
                window.history.back();
            }
        },

        handleBack() {
            const {customBack} = this;

            if (customBack) {
                customBack(this.goBack);
            } else {
                this.goBack();
            }
        },

        observeTitle() {
            const target = document.querySelector('title');
            const observer = new MutationObserver(() => {
                this.newTitle = document.title;
            });
            observer.observe(target, {
                subtree: true,
                characterData: true,
                childList: true
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.nav-bar-container {
    @supports ((height: constant(safe-area-inset-top)) or (height: env(safe-area-inset-top))) and (-webkit-overflow-scrolling: touch) {
        /* 适配齐刘海 */
        padding-top: 0;
        padding-top: constant(safe-area-inset-top);
        padding-top: env(safe-area-inset-top);
    }

    z-index: 100;

    .nav-bar {
        // height: .5rem;
        padding: 0.2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &-tit {
            max-width: 60%;
            font-size: 0.15rem;
            font-family: Helvetica-Bold, Helvetica;
            font-weight: bold;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        &-left {
            display: flex;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            padding-left: 0.16rem;

            &-icon {
                width: 0.24rem;
            }
        }

        &-right {
            display: flex;
            align-items: center;
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
        }
    }
}
</style>
