/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-02-04 18:51:15
 */

import Vue from 'vue';

import {isServer} from 'ssr-common/constant/config';
import http from 'ssr-common/vue/vue-http';

import App from './app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';

import 'ssr-common/vue/vue-error-handler';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter();
    const store = createStore();

    if (!isServer()) {
        // 从 context 中获取数据，注入到 store 中
        const {businessData} = context; // businessData 是从配置中心获取的业务数据
        window.__BUSINESS_DATA__ = businessData; // eslint-disable-line
    }

    http.defaults.headers.common.build = context.build || '36000';

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, router, store};
};
