/*
 * @Author: zhaoyang
 * @Date: 2022-09-07 14:23:37
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-05-30 17:49:46
 */

import StageType from 'ssr-common/constant/stage-type';

export default Vue => ({
    async mounted() {
        if (this.getBusinessData) {
            const initVConsole = this.getBusinessData('initVConsole');
            if (!initVConsole) return;
        }

        if ([StageType.dev, StageType.testOverseas, StageType.testIndo, StageType.testMex, StageType.testSea].includes(__STAGE__)) { // eslint-disable-line no-undef
            const {default: VConsole} = await import('vconsole');
            Vue.use(new VConsole());
        }
    }
});
