/*
 * @Author: chengyuzhang
 * @Date: 2020-09-28 11:13:59
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2020-09-28 11:46:00
 */

export default {
    namespaced: true,

    state: () => ({
        primaryColor: '#00c989',
        brand: 'easycash'
    })
};
