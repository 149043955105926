/*
 * @Author: chengyuzhang
 * @Date: 2020-11-11 11:30:00
 * @Last Modified by: bowang
 * @Last Modified time: 2023-04-21 11:49:22
 */
import { isIOS } from 'ssr-common/util/webview/ua';
const initBar = ({ color, right, width }) => {
    const barEl = document.createElement('div');
    const props = { color, right, width, borderRadius: width };
    barEl.classList.add('scroll-bar');
    Object.keys(props).forEach(prop => {
        if (props[prop])
            barEl.style[prop] = props[prop];
    });
    return barEl;
};
const getTop = (el) => el.getBoundingClientRect().top;
const scrollHandler = (scrollEl, barEl, paddingTop) => {
    barEl.style.opacity = '1';
    const { scrollTop, scrollHeight, offsetHeight } = scrollEl;
    barEl.style.top = `${(scrollTop / scrollHeight) * offsetHeight + paddingTop}px`;
    barEl.style.height = `${(offsetHeight / scrollHeight) * offsetHeight}px`;
    setTimeout(() => {
        barEl.style.opacity = '0';
    }, 700);
};
const directive = {
    inserted(el, { value }) {
        if (isIOS(navigator.userAgent))
            return;
        const options = value;
        const { parentElement } = el;
        const barEl = initBar(options);
        const parentPaddingTop = getTop(el) - getTop(parentElement);
        parentElement.style.position = 'relative';
        parentElement.appendChild(barEl);
        setTimeout(() => {
            barEl.style.opacity = '0';
        }, 700);
        if (typeof window.MutationObserver === 'function') {
            const observer = new MutationObserver(() => {
                scrollHandler(el, barEl, parentPaddingTop);
            });
            observer.observe(el, {
                childList: true,
                attributes: true,
                attributeFilter: ['style'],
                attributeOldValue: true
            });
        }
        el.addEventListener('scroll', () => {
            scrollHandler(el, barEl, parentPaddingTop);
        });
    }
};
export default directive;
