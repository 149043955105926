/*
 * @Author: zhaoyang
 * @Date: 2024-01-28 20:52:45
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-31 10:36:17
 */
import { debounce } from 'lodash';
export default {
    bind(el, { value, arg, modifiers }, vnode) {
        const handleEvent = (event, type) => {
            const elementContent = { ...value };
            if (['Input', 'Blur'].includes(type)) {
                elementContent.inputVal = event?.target?.value;
            }
            vnode.context.$app.uploadClickEvent(`${arg}${type}`, elementContent);
        };
        const hasClickEvent = !Object.keys(modifiers)?.length;
        if (hasClickEvent || modifiers.click) {
            el.addEventListener('click', event => handleEvent(event, 'Click'));
        }
        if (modifiers.focus) {
            el.addEventListener('focus', event => handleEvent(event, 'Focus'));
        }
        if (modifiers.blur) {
            el.addEventListener('blur', event => handleEvent(event, 'Blur'));
        }
        if (modifiers.input) {
            el.addEventListener('input', debounce(event => handleEvent(event, 'Input'), 500));
        }
    }
};
