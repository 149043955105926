var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.visible,
          expression: "visible",
        },
      ],
      staticClass: "nav-bar-container",
    },
    [
      _c("div", { staticClass: "nav-bar", style: _vm.navStyle }, [
        !_vm.noReturn
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "yqg-event",
                    rawName: "v-yqg-event",
                    value: _vm.event,
                    expression: "event",
                  },
                ],
                staticClass: "nav-bar-left",
                on: { click: _vm.handleBack },
              },
              [
                _vm._t("left", function () {
                  return [
                    _c(
                      "back-icon",
                      _vm._b(
                        {
                          staticClass: "nav-bar-lef-icon",
                          style: { left: _vm.backLeft },
                        },
                        "back-icon",
                        { color: _vm.color, newArrow: _vm.newArrow },
                        false
                      )
                    ),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "nav-bar-tit" },
          [
            _vm._t("title", function () {
              return [
                !_vm.noTitle
                  ? _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.title) +
                          "\n                "
                      ),
                    ])
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "nav-bar-right" }, [_vm._t("right")], 2),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }