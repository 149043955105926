/*
 * @Author: ruiwang
 * @Date: 2024-02-04 18:39:02
 * @Last Modified by: ruiwang
 * @Last Modified time: 2024-02-05 15:09:05
 */

import {isServer} from 'ssr-common/constant/config';

export default {
    methods: {
        getBusinessData(key) {
            let businessData = {};
            if (isServer()) {
                businessData = this.$ssrContext.businessData;
            } else {
                businessData = window.__BUSINESS_DATA__; // eslint-disable-line
            }

            if (!key) {
                return businessData;
            }

            return businessData?.[key];
        }
    }
};
