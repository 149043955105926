/*
 * @Author: chengyuzhang
 * @Date: 2021-06-24 15:27:43
 * @Last Modified by: bowang
 * @Last Modified time: 2023-04-21 11:49:54
 */
const directive = {
    inserted(el, { value = {} }) {
        const { color = 'rgba(0, 0, 0, .05)', duration = 0.4, radius = 1.5, disabled = false } = value;
        let exist = false;
        el.rippleDisabled = disabled;
        el.addEventListener('click', ({ offsetX, offsetY }) => {
            if (exist || el.rippleDisabled)
                return;
            const { offsetWidth: btnWidth, offsetHeight: btnHeight } = el;
            const rippleRadius = Math.max(btnWidth, btnHeight);
            const ripple = document.createElement('div');
            ripple.style.background = color;
            ripple.style.transition = `all ${duration}s ease`;
            ripple.style.position = 'absolute';
            ripple.style.borderRadius = '50%';
            ripple.style.width = '0';
            ripple.style.height = '0';
            ripple.style.top = `${offsetY}px`;
            ripple.style.left = `${offsetX}px`;
            ripple.style.transform = 'translate(-50%, -50%)';
            if (window.getComputedStyle(el).position === 'static') {
                el.style.position = 'relative';
            }
            el.style.overflow = 'hidden';
            el.appendChild(ripple);
            exist = true;
            setTimeout(() => {
                const realRadius = rippleRadius * radius;
                ripple.style.width = `${realRadius}px`;
                ripple.style.height = `${realRadius}px`;
            }, 0);
            setTimeout(() => {
                if (!exist)
                    return;
                el.removeChild(ripple);
                exist = false;
            }, (duration * 1000));
        });
    },
    update(el, { value: { disabled } = {} }) {
        setTimeout(() => {
            el.rippleDisabled = disabled;
        }, 100);
    }
};
export default directive;
