/*
 * @Author: zhaoyang
 * @Date: 2022-12-06 20:53:23
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-01-18 11:34:45
 */

export const TagPrefix = 'MergeMobile';
const BusinessPrefix = 'Easycash_MergeMobile_';

export const MergeMobilePageEnter = {
    tag: `${TagPrefix}PageEnter`,
    businessEvent: `${BusinessPrefix}PageEnter`,
    elementType: 'Button',
    eventType: 'C'
};

export const MergeMobileOtpPageEnter = {
    tag: `${TagPrefix}OtpPageEnter`,
    businessEvent: `${BusinessPrefix}OtpPageEnter`,
    elementType: 'Button',
    eventType: 'P'
};

export const MergeMobileResultPageEnter = {
    tag: `${TagPrefix}ResultPageEnter`,
    businessEvent: `${BusinessPrefix}ResultPageEnter`,
    elementType: 'Button',
    eventType: 'P'
};

export const MergeMobileUploadInfoPageEnter = {
    tag: `${TagPrefix}UploadInfoPageEnter`,
    businessEvent: `${BusinessPrefix}UploadInfoPageEnter`,
    elementType: 'Button',
    eventType: 'P'
};

export default {
    MobileOtpInputClick: `${TagPrefix}_MobileOtpInputClick`,
    NowMobileInputClick: `${TagPrefix}_NowMobileInputClick`,
    OldMobileInputClick: `${TagPrefix}_OldMobileInputClick`,
    SubmitKtpInfoBtn: `${TagPrefix}_SubmitKtpInfoBtn`,
    SendMobileOtpBtn: `${TagPrefix}_SendMobileOtpBtn`,
    StopMobileBtn: `${TagPrefix}_StopMobileBtn`,
    AccountItemClick: `${TagPrefix}_AccountItemClick`,
    NoMergeClick: `${TagPrefix}_NoMergeClick`,
    MergeClick: `${TagPrefix}_MergeClick`,
    OtpSuccess: {
        tag: `${TagPrefix}OtpSuccess`,
        businessEvent: `${BusinessPrefix}OtpSuccess`,
        elementType: 'Button',
        eventType: 'C'
    },
    UploadInfo: {
        tag: `${TagPrefix}UploadInfo`,
        businessEvent: `${BusinessPrefix}UploadInfo`,
        elementType: 'Button',
        eventType: 'C'
    },
    DialogMergeClick: {
        tag: `${TagPrefix}DialogMergeClick`,
        businessEvent: `${BusinessPrefix}DialogMergeClick`,
        elementType: 'Button',
        eventType: 'C'
    },
    DialogNoMergeClick: {
        tag: `${TagPrefix}DialogNoMergeClick`,
        businessEvent: `${BusinessPrefix}DialogNoMergeClick`,
        elementType: 'Button',
        eventType: 'C'
    }
};
