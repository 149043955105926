/*
 * @Author: ruiwang
 * @Date 2021-09-09 17:22:30
 * @Last Modified by: ruiwang
 * @Last Modified time: 2021-09-09 17:40:13
 */

export default {
    install(Vue) {
        Vue.filter('money', val => val && `${val}`
            .replace('.', ',')
            .replace(/(^|\s)\d+/g, str => str.replace(/(?=(?!\b)(\d{3})+$)/g, '.')));
    }
};

