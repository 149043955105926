/*
 * @Author: zhaoyang
 * @Date: 2022-11-21 19:11:31
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-01-09 14:56:21
 */

export const TagPrefix = 'LoanEditMobile';
const BusinessPrefix = 'Easycash_LoanEditMobile_';

export const EditMobileUploadInfoPageEnter = {
    tag: `${TagPrefix}UploadInfoPageEnter`,
    businessEvent: `${BusinessPrefix}UploadInfoPageEnter`,
    elementType: 'Button',
    eventType: 'P'
};

export default {
    IdentityNumberBtn: `${TagPrefix}_IdentityNumberBtn`,
    IdentityNumberInputClick: `${TagPrefix}_IdentityNumberInputClick`,
    SendMobileOtpBtn: `${TagPrefix}_SendMobileOtpBtn`,
    MobileOtpInputClick: `${TagPrefix}_MobileOtpInputClick`,
    PhoneStopBtn: `${TagPrefix}_PhoneStopBtn`,
    CustomerServiceBtn: `${TagPrefix}_CustomerServiceBtn`,
    NewMobileBtn: `${TagPrefix}_NewMobileBtn`,
    NewMobileInputClick: `${TagPrefix}_NewMobileInputClick`,
    NowMobileInputClick: `${TagPrefix}_NowMobileInputClick`,
    OldMobileInputClick: `${TagPrefix}_OldMobileInputClick`,
    SubmitKtpInfoBtn: `${TagPrefix}_SubmitKtpInfoBtn`,

    EditModeResult: (code, mobileNumber) => ({
        tag: `${TagPrefix}Result`,
        businessEvent: `${BusinessPrefix}Result`,
        elementType: 'Button',
        eventType: 'C',
        elementContent: {code, mobileNumber}
    })
};
