/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-28 15:38:53.379
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-12-14 18:20:50
 */

import EcImage from 'ssr-common/vue/component/ec-image';
import EcLayout from 'ssr-common/vue/component/ec-layout';

import Filters from '../filter';

export default {
    install(Vue) {
        Vue.use(Filters);
        Vue.component('ec-layout', EcLayout);
        Vue.component('ec-image', EcImage);
        Vue.component('ec-header', () => import('./ec-header'));
        Vue.component('ec-header-curtain', () => import('./ec-header-curtain'));
        Vue.component('ec-footer', () => import('./ec-footer'));
        Vue.component('ec-modal', () => import('./ec-modal'));
        Vue.component('ec-webview-loading-circle', () => import('./ec-webview-loading-circle'));
        Vue.component('ec-questionnaire', () => import('./ec-questionnaire'));
        Vue.component('ec-expand-box', () => import('./ec-expand-box'));
        Vue.component('ec-table', () => import('./ec-table'));
        Vue.component('yqg-md-preview', () => import('ssr-common/vue/component/yqg-md-preview'));
        Vue.component('yqg-pdf-viewer', () => import('ssr-common/vue/component/yqg-pdf-viewer'));
        Vue.component('ec-btn', () => import('./ec-btn'));
        Vue.component('nav-bar', () => import('./ec-nav-bar'));
        Vue.component('input-item', () => import('./ec-form/input-item'));
    }
};
