/*
 * @Author: zhaoyang
 * @Date: 2023-04-20 18:04:14
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2024-01-28 19:16:53
 */
export const genClickEvent = (brand, tag, elementContent) => ({
    tag,
    businessEvent: `${brand}_${tag}`,
    elementType: 'Button',
    eventType: 'C',
    elementContent
});
export const genEnterEvent = (brand, route, elementContent) => ({
    tag: 'PageEnter',
    businessEvent: `${brand}_${route.name}_PageEnter`,
    elementType: 'Button',
    eventType: 'P',
    elementContent
});
export const genEasycashClickEvent = (tag, elementContent) => genClickEvent('Easycash', tag, elementContent);
export const genInvestajaClickEvent = (tag, elementContent) => genClickEvent('Investaja', tag, elementContent);
